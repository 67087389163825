<template>
  <header class="header">
    <div class="container">
      <span class="menu" @click="handleMenu"></span>
      <router-link to="/"
        ><span class="logo"
          ><span>FOX</span><span>READ</span></span
        ></router-link
      >
      <div class="nav">
        <router-link to="/">Главная</router-link>
        <router-link :to="{ name: 'home', params: { type: 'Здоровье' } }"
          >Здоровье</router-link
        >
        <router-link :to="{ name: 'home', params: { type: 'Развлечения' } }"
          >Развлечения</router-link
        >
        <router-link :to="{ name: 'home', params: { type: 'Путешествия' } }"
          >Путешествия</router-link
        >
        <router-link :to="{ name: 'home', params: { type: 'Юмор' } }"
          >Юмор</router-link
        >
      </div>
      <span class="search"></span>
    </div>
    <div v-if="openMenu" class="moduleMenu">
      <router-link to="/">Главная</router-link>
      <router-link :to="{ name: 'home', params: { type: 'Здоровье' } }"
        >Здоровье</router-link
      >
      <router-link :to="{ name: 'home', params: { type: 'Развлечения' } }"
        >Развлечения</router-link
      >
      <router-link :to="{ name: 'home', params: { type: 'Путешествия' } }"
        >Путешествия</router-link
      >
      <router-link :to="{ name: 'home', params: { type: 'Юмор' } }"
        >Юмор</router-link
      >
    </div>
  </header>
</template>
<script>
export default {
  name: "HeaderComp",
  data() {
    return {
      openMenu: false,
    };
  },
  setup() {},
  methods: {
    handleMenu() {
      if (this.openMenu === false) {
        this.openMenu = true;
      } else {
        this.openMenu = false;
      }
    },
  },
};
</script>
