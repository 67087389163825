import Axios from './AxiosClass'

class ApiModel extends Axios {

    getModels(params) {
        return super.get(this.baseUrl, params)
    }

    getModel(id, params) {
        return super.get(`${this.baseUrl}/${id}`, params)
    }

    createModel(data) {
        return super.post(this.baseUrl, data)
    }

    updateModel(id, data) {
        return super.put(`${this.baseUrl}/${id}`, data)
    }

    deleteModel(id) {
        return super.delete(`${this.baseUrl}/${id}`)
    }
}

export default ApiModel;