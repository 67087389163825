<template>
  <div class="news container">
    <div class="content">
      <h1>{{ data.title }}</h1>
      <span class="content-text" v-html="data.text"></span>
    </div>
    <div class="news-info"><b>Автор:</b> {{data.author}}</div>
  </div>
</template>

<script>
import news from "@/composables/views/news.ts";

export default {
  name: "NewsView",
  setup() {
    const { data } = news();

    return { data };
  },
};
</script>
